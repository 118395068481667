import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from '../../settings';
import CompanyLogo from "./companyLogo";

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/admin">
              <i className={siteConfig.siteIcon} />
            </Link>
          </h3>
        </div>
      ) : (
        <CompanyLogo/>
      )}
    </div>
  );
};
