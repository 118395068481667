import {apiDomain} from "./config"
import Cookies from "universal-cookie";

let cookies = new Cookies();

function getAPI(url, successCallback, failCallback){
    let isFailed = false;

    let urlWithToken = new URL(url);
    if (cookies.get('token') && !urlWithToken.searchParams.has('access_token')){
        urlWithToken.searchParams.set('access_token', cookies.get('token'));
        url = urlWithToken.toString();
    }


    fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json, text/plain, /',
            'Content-Type': 'application/json'
        }
    }).then(res => {
        if (res.status === 200 || res.status === 202) {
            return res.json();
        } else {
            isFailed = true;
            return res.json();
        }
    })
        .then(response => {
            if (isFailed) throw response;
            return successCallback(response)
        })
        .catch(error => failCallback(error))
}

export default getAPI;

