import { apiDomain } from "../config";
import getAPI from "../baseGetAPI";

function getErrorNotiCount(token, successCallback, failCallback) {
  getAPI(
    `${apiDomain}error_log/error_count?access_token=${token}`,
    successCallback,
    failCallback
  );
}

function getOrderErrorCount(token, successCallback, failCallback) {
  getAPI(
    `${apiDomain}bookings/error_count?access_token=${token}`,
    successCallback,
    failCallback
  );
}

function getInvoicesCount(token, successCallback, failCallback) {
  getAPI(
    `${apiDomain}invoices/error_count?access_token=${token}`,
    successCallback,
    failCallback
  );
}

function getResidentOCRCount(token, successCallback, failCallback) {
  getAPI(
    `${apiDomain}residentOCR/error_count?access_token=${token}`,
    successCallback,
    failCallback
  );
}

export {
  getOrderErrorCount,
  getErrorNotiCount,
  getInvoicesCount,
  getResidentOCRCount,
};
