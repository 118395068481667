// const apiDomain = "http://ec2-13-229-104-6.ap-southeast-1.compute.amazonaws.com/api/";

var apiDomain = "https://uat-api-enterprise.letspark.com.hk/api/";
var opsDomain = "https://lspe-staging-ops.letspark.com.hk/api/";
var aggregatorDomain = "https://uat-api.letspark.com.hk/api/";

if (process.env.REACT_APP_ENV == "prod") {
  apiDomain = "https://api-enterprise.letspark.com.hk/api/";
  opsDomain = "https://lspe-prd-ops.letspark.com.hk/api/";
  aggregatorDomain = "https://api.letspark.com.hk/api/";
}

export { apiDomain, opsDomain, aggregatorDomain };
