import React, { Component } from 'react';
import getMerchantUserByMidAPI from "../../api/merchant/getMerchantUserByMidAPI";
import { Link } from 'react-router-dom';
import { siteConfig } from '../../settings';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class CompanyLogo extends Component {

    constructor(props){
        super(props);
        this.state = {
            company: {}
        }
    }

    componentDidMount() {
        getMerchantUserByMidAPI(cookies.get('mid'), cookies.get('token'),
            (response) => {
                // console.log(response);
                this.setState({
                    company: response,
                })
            },
            (error) => {
                console.log(error);
                alert(error.msg || 'Unknown error. Please try again.');
            }
        )
    }

    render() {
        if (this.state.company.logo){
            return (
                <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={this.state.company.logo} alt={siteConfig.siteName} style={{width: '100%', height: 'auto', objectFit: 'contain'}}/>
                </div>
            )
        } else {
            return (
                <h3>
                    <Link to="/admin">{siteConfig.siteName}</Link>
                </h3>
            )
        }
    }

}
