import { Map } from "immutable";

export function clearToken() {
  localStorage.removeItem("id_token");
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function safeGetFromObject(obj, key) {
  return key.split(".").reduce(function (o, x) {
    return typeof o == "undefined" || o === null ? o : o[x];
  }, obj);
}

export function getTransactionStatusName(status) {
  if (!status) return "-";
  switch (status.toLowerCase()) {
    case "pending":
    case "pendingtoreview":
      return "Pending to Review";
    case "pendingtoprocess":
      return "Pending to Process";
    case "confirmed":
      return "Confirmed";
    case "error":
      return "Error";
    default:
      return "-";
  }
}

export function getPaymentMethodName(paymentMethod) {
  if (!paymentMethod) return "-";
  switch (paymentMethod.toLowerCase()) {
    case "711":
      return "7-11";
    case "fps":
      return "FPS";
    case "creditcard":
      return "Credit Card";
    case "cheque":
      return "Cheque";
    default:
      return paymentMethod;
  }
}

export function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function get711AccountNumber(transactionId) {
  return (
    pad(parseInt(transactionId.toString().substring(0, 8), 16), 10) +
    pad(parseInt(transactionId.toString().substring(20), 16), 6)
  );
}

export function dateFromObjectId(objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
}

export function getOCRStatusName(status) {
  switch (status) {
    case "pending":
      return "OCR - Pending";
    case "success":
      return "OCR - Scanned";
    case "error":
      return "OCR - Error";
    case "approved":
      return "OCR - Manually Approved";
    default:
      return "N/A";
  }
}

export function getFeatureName(feature) {
  switch (feature) {
    case "Indoor":
      return "Indoor";
    case "With Covers":
      return "WithCovers";
    case "EV":
      return "EV";
    case "HeadRoom":
      return "HeadRoom";
    case "No Covers":
      return "NoCovers";
    default:
      return "";
  }
}

export function getCarTypeName(cartype) {
  switch (cartype) {
    case "Private Car":
      return "PrivateCar";
    case "Motorcycle":
      return "Motorcycle";
    case "Truck":
      return "Lorry";
    case "Van":
      return "Van";
    default:
      return "Others";
  }
}

export function getRestrictionName(restriction) {
  switch (restriction) {
    case "For owners in this building":
      return "Owners";
    case "For owners and parkers in this building":
      return "Parkers";
    case "For visitors only":
      return "Visitors";
    case "No restrictions":
      return "No";
    default:
      return "No";
  }
}

export function getPeriodName(period) {
  switch (period) {
    case "monfri":
      return "Mon to Fri";
    case "satsunph":
      return "Sat, Sun & PH";
    case "monsunph":
      return "Mon to Sun & PH";
  }
}

export function getHeadRoom(headroom = "") {
  switch (headroom) {
    case "1.6m":
      return "1.7";
    case "1.7m":
      return "1.7";
    case "1.8m":
      return "1.8";
    case "1.9m":
      return "1.9";
    case "2.0m":
      return "2.0";
    case "2.1m":
      return "2.1";
    case "2.2m":
      return "2.2";
    case "2.3m":
      return "2.3";
    case "2.4m":
      return "2.4orAbove";
    case "2.4m_up":
      return "2.4orAbove";
    case "noLimit":
      return "2.4orAbove";

    default:
      return `${headroom.replace("m", "")}`;
  }
}

export function getAccessType(accessType) {
  switch (accessType) {
    case "AccessCard":
      return "AccessCard";
    case "OctopusCard":
      return "OctopusCard";
    case "AccessTypeNo":
      return "No";
    case "None":
      return "No";
    default:
      return "No";
  }
}
