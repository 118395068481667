import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import options from "./options";
import Scrollbars from "../../components/utility/customScrollBar.js";
import Menu from "../../components/uielements/menu";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
import Logo from "../../components/utility/logo";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import Cookies from "universal-cookie";
import getMerchantUserContactInfoAPI from "../../api/merchant/getMerchantUserContactInfoAPI";
import getMerchantByMidAPI from "../../api/merchant/getMerchantByMidAPI";
import { safeGetFromObject } from "../../helpers/utility";
import {
  getOrderErrorCount,
  getInvoicesCount,
  getResidentOCRCount,
  getErrorNotiCount,
} from "../../api/error_count";

const cookies = new Cookies();

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
  setErrorNotiCount,
  setOrderErrorCount,
  setInvoicesCount,
  setResidentOCRCount,
} = appActions;

const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDemo: false,
      sideMenuItems: [],
      isAggregatorAllowed: false,
      isOCRRecordsAllowed: false,
      isACSBookingsAllowed: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  componentDidMount() {
    this.getErrorCount();

    getMerchantByMidAPI(
      cookies.get("mid"),
      (response) => {
        this.setState(
          {
            isAggregatorAllowed: response.features.integration
              ? response.features.integration.aggregator || false
              : false,
            isACSBookingsAllowed: response.features.integration
              ? response.features.integration.acs || false
              : false,
            isOCRRecordsAllowed: response.features.addons
              ? response.features.addons.resident_ocr || false
              : false,
          },
          this.fetchUserPermissions
        );
      },
      (error) => {
        console.log(error);
        alert(error.msg || "Unknown error. Please try again.");
      }
    );
  }

  componentDidUpdate() {}

  getErrorCount = () => {
    getErrorNotiCount(
      cookies.get("token"),
      (response) => {
        this.props.setErrorNotiCount(response.total);
      },
      (error) => {
        console.log(error);
      }
    );

    getOrderErrorCount(
      cookies.get("token"),
      (response) => {
        this.props.setOrderErrorCount(response.total);
      },
      (error) => {
        console.log(error);
      }
    );

    getInvoicesCount(
      cookies.get("token"),
      (response) => {
        this.props.setInvoicesCount(response.total);
      },
      (error) => {
        console.log(error);
      }
    );

    getResidentOCRCount(
      cookies.get("token"),
      (response) => {
        this.props.setResidentOCRCount(response.total);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  fetchUserPermissions = () => {
    getMerchantUserContactInfoAPI(
      cookies.get("token"),
      (response) => {
        // console.log(response);
        this.setState(
          {
            isDemo: !response.roles || response.roles === "Demo",
          },
          () => {
            let { permissions, level, all_granted, features } = response;
            this.menuItemsPermissionFilter(
              permissions,
              level,
              all_granted,
              features
            );
          }
        );
      },
      (error) => {
        console.log(error);
        this.setState({ loading: false, visible: true });
        alert(error.msg || "Unknown error. Please try again.");
      }
    );
  };

  menuItemsPermissionFilter(
    permissions = [],
    level = "enterprise",
    all_granted = true,
    features
  ) {
    let sideMenuItems = options;

    if (features) {
      if (!safeGetFromObject(features, "addons.custom_msg")) {
        let messageItem = sideMenuItems.find((item) => item.key === "messages");
        if (messageItem)
          messageItem.children = messageItem.children.filter(
            (child) => child.key !== "messageCustomizedMessage"
          );
      }
      if (!safeGetFromObject(features, "integration.aggregator")) {
        sideMenuItems = sideMenuItems.filter(
          (item) => item.key !== "aggregator"
        );
      }
    }
    if (this.state.isDemo) {
      sideMenuItems = sideMenuItems.reduce((acc, item) => {
        if (
          [
            "dashboard",
            "message",
            "payments",
            "appBanner",
            "accountSettings",
            "myAccount",
          ].indexOf(item.key) !== -1
        )
          return acc;
        let extractedItem = {
          ...item,
          children: item.children
            ? item.children.filter(
                (child) =>
                  ["template", "SEO", "quotaStatus"].indexOf(child.key) === -1
              )
            : null,
        };
        acc.push(extractedItem);
        return acc;
      }, []);
    } else {
      //Check Levels

      const levels = {
        super: ["super"],
        enterprise: ["enterprise", "carpark"],
        carpark: ["carpark"],
      };

      for (let sideMenuItem of sideMenuItems) {
        if (sideMenuItem.children) {
          sideMenuItem.children = sideMenuItem.children.filter(
            (child) => !child.level || levels[level].indexOf(child.level) !== -1
          );
        }
      }

      sideMenuItems = sideMenuItems.filter((item) => {
        if (item.children) {
          item.children = item.children.filter((child) => {
            return !child.level || levels[level].indexOf(child.level) !== -1;
          });
          return item.children.length > 0;
        } else {
          return !item.level || levels[level].indexOf(item.level) !== -1;
        }
      });

      if (!all_granted) {
        for (let sideMenuItem of sideMenuItems) {
          if (sideMenuItem.children) {
            sideMenuItem.children = sideMenuItem.children.filter(
              (child) =>
                !child.module ||
                permissions.some(
                  (permission) =>
                    permission.module === child.module &&
                    permission.action === "read"
                )
            );
          }
        }
        sideMenuItems = sideMenuItems.filter((item) => {
          if (item.children) {
            return item.children.length > 0;
          } else {
            return (
              !item.module ||
              permissions.some(
                (permission) =>
                  permission.module === item.module &&
                  permission.action === "read"
              )
            );
          }
        });
      }

      if (!this.state.isAggregatorAllowed) {
        sideMenuItems = sideMenuItems.filter(
          (item) => item.key !== "aggregator"
        );
      }

      if (!this.state.isOCRRecordsAllowed) {
        sideMenuItems.forEach((largeItem) => {
          if (!!largeItem.children == true) {
            largeItem.children = largeItem.children.filter(
              (miniItem) => miniItem.key !== "records"
            );
          }
        });
      }

      if (!this.state.isACSBookingsAllowed) {
        sideMenuItems.forEach((largeItem) => {
          if (!!largeItem.children == true) {
            largeItem.children = largeItem.children.filter(
              (miniItem) => miniItem.key !== "acsBookings"
            );
          }
        });
      }
    }
    this.setState({ sideMenuItems });
  }

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };
  getMenuItem = ({
    singleOption,
    submenuStyle,
    submenuColor,
    collapsed,
    errorCount,
  }) => {
    const { key, label, leftIcon, children } = singleOption;
    const url = stripTrailingSlash(this.props.url);

    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <img
                src={`/img/icon/${leftIcon}`}
                style={{ width: "20px", marginRight: "10px" }}
              />
              {
                // <Icon name={leftIcon} />
              }
              <span className="nav-text" style={{ fontSize: "0.85rem" }}>
                <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {children.map((child) => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;

            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
                {typeof errorCount[child.key] === "number" && (
                  <div
                    style={{
                      width: 25,
                      height: 25,
                      borderRadius: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "red",
                      color: "#fff",
                      marginLeft: 10,
                    }}
                    className="countDiv"
                  >
                    {errorCount[child.key]}
                  </div>
                )}
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <img
              src={`/img/icon/${leftIcon}`}
              style={{ width: "20px", marginRight: "10px" }}
            />

            <span className="nav-text" style={{ fontSize: "0.85rem" }}>
              <IntlMessages id={label} />
            </span>
            {typeof errorCount[key] === "number" && (
              <div
                style={{
                  width: 25,
                  height: 25,
                  borderRadius: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "red",
                  color: "#fff",
                  marginLeft: collapsed ? -10 : 10,
                }}
                className="countDiv"
              >
                {errorCount[key]}
              </div>
            )}
          </span>
        </Link>
      </Menu.Item>
    );
  };
  render() {
    const { app, toggleOpenDrawer, height } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer, errorCount } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = (event) => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {this.state.sideMenuItems.map((singleOption) =>
                this.getMenuItem({
                  submenuStyle,
                  submenuColor,
                  singleOption,
                  collapsed,
                  errorCount,
                })
              )}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    app: state.App,
    height: state.App.height,
  }),
  {
    toggleOpenDrawer,
    changeOpenKeys,
    changeCurrent,
    toggleCollapsed,
    setErrorNotiCount,
    setOrderErrorCount,
    setInvoicesCount,
    setResidentOCRCount,
  }
)(Sidebar);
