import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';

import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login-email',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <div>

        <Route
          exact path={'/login-email'}
          component={asyncComponent(() => import('./containers/Login/loginEmail'))}
         />
         <Route
           exact path={'/login-mobile'}
           component={asyncComponent(() => import('./containers/Login/loginMobile'))}
          />
          <Route
            exact path={'/create-idv'}
            component={asyncComponent(() => import('./containers/CreateUserIdv/index'))}
           />
           <Route
             exact path={'/create-idv-create'}
             component={asyncComponent(() => import('./containers/CreateUserIdv/createIdvPage'))}
            />
           <Route
            exact path={'/create-corporate'}
            component={asyncComponent(() => import('./containers/CreateCorporate/index'))}
           />
        <Route
          exact
          path={'/'}
          component={asyncComponent(() => import('./containers/Login/loginEmail'))}
        />


        <RestrictedRoute
          path="/admin"
          component={App}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null
}))(PublicRoutes);
