const options = [
    // {
    //   key: 'blankPage',
    //   label: 'sidebar.blankPage',
    //   leftIcon: 'ion-document',
    // },
    // {
    //   key: 'authCheck',
    //   label: 'sidebar.authCheck',
    //   leftIcon: 'ion-document',
    // },
    {
        key: 'dashboard',
        module: 'dashboard',
        level: 'carpark',
        label: 'sidebar.dashboard',
        leftIcon: '016-dashboard.png',
    },
    {
        key: 'merchants',
        module: 'enterprise_merchants',
        level: 'super',
        label: 'sidebar.merchants',
        leftIcon: 'merchant.png',
    },
    {
        key: 'errorNotifications',
        module: 'error_log',
        label: 'sidebar.errorNotifications',
        leftIcon: 'email_alert_icon.png',
        errorCount:0,
    },
    {
        key: 'mysite',
        label: 'sidebar.mysite',
        leftIcon: 'my-site.png',
        children: [
            // {
            //     key: 'template',
            //     module: 'sites',
            //     level: 'enterprise',
            //     label: 'sidebar.mySiteTemplate',
            // },
            {
                key: 'contentUpdate',
                module: 'sites',
                level: 'enterprise',
                label: 'sidebar.mySiteContentUpdate',
            },
            // {
            //     key: 'SEO',
            //     module: 'sites',
            //     label: 'sidebar.mySiteSEO',
            // },
        ],
    },
    {
        key: 'settings',
        label: 'sidebar.carparkSettings',
        leftIcon: '011-parking.png',
        children: [
            {
                key: 'carparkInfo',
                module: 'carparks',
                level: 'carpark',
                label: 'sidebar.carparkSettingsCarparkInfo',
            },
            {
                key: 'quotaStatus',
                module: 'carparks',
                level: 'carpark',
                label: 'sidebar.carparkSettingsQuotaStatus',
            },
            {
                key: 'acsBookings',
                module: 'carparks',
                level: 'enterprise',
                label: 'sidebar.ACSBookings',
            },],
    },
    {
        key: 'customers',
        label: 'sidebar.customers',
        leftIcon: '013-profile.png',
        children: [
            {
                key: 'customers',
                module: 'users',
                level: 'carpark',
                label: 'sidebar.customers',
            },
            {
                key: 'records',
                module: 'ocr_records',
                level: 'carpark',
                label: 'sidebar.ocrRecords',
            },
        ]
    },
    {
        key: 'modules',
        module: 'modules',
        level: 'enterprise',
        label: 'sidebar.modules',
        leftIcon: 'module.png',
    },
    {
        key: 'messages',
        label: 'sidebar.messages',
        leftIcon: 'message.png',
        children: [
            {
                key: 'message',
                module: 'notifications',
                level: 'carpark',
                label: 'sidebar.message'
            },
            {
                key: 'enquiry',
                module: 'notifications',
                level: 'carpark',
                label: 'sidebar.messageEnquiry'
            },
            {
                key: 'messageCustomizedMessage',
                module: 'notifications',
                level: 'carpark',
                label: 'sidebar.messageCustomizedMessage'
            }
        ]
    },
    {
        key: 'payments',
        label: 'sidebar.payments',
        leftIcon: 'payment.png',
        children: [
            // {
            //   key: 'oneTimePayment',
            //   label: 'sidebar.paymentsOneTimePayment',
            // },
            {
                key: 'paymentOrders',
                module: 'bookings',
                level: 'carpark',
                label: 'sidebar.paymentsPaymentOrders',
            },
            {
                key: 'subOrders',
                module: 'invoices',
                level: 'carpark',
                label: 'sidebar.paymentsSubOrders',
            },
            {
                key: 'transactions',
                module: 'transactions',
                level: 'carpark',
                label: 'sidebar.paymentsTransactions',
            },],
    },
    // {
    //   key: 'settings',
    //   label: 'sidebar.settings',
    //   leftIcon: 'setting',
    // },
    {
        key: 'appBanner',
        module: 'banners',
        level: 'super',
        label: 'sidebar.appBanner',
        leftIcon: '03-advertising.png'
    },
    {
        key: 'aggregator',
        module: 'aggregator',
        level: 'enterprise',
        label: 'sidebar.aggregator',
        leftIcon: '03-advertising.png'
    },
    {
        key: 'publicHolidays',
        module: 'holidays',
        level: 'super',
        label: 'sidebar.publicHolidays',
        leftIcon: 'holiday.png'
    },
    {
        key: 'accountSettings',
        label: 'sidebar.accountSettings',
        leftIcon: 'setting.png',
        children: [
            {
                key: 'basicInfo',
                module: 'merchants',
                level: 'enterprise',
                label: 'sidebar.accountSettingsBasicInfo',
            },
            {
                key: 'roles',
                module: 'merchants',
                level: 'enterprise',
                label: 'sidebar.accountSettingsRoles',
            },
            {
                key: 'users',
                module: 'merchant_users',
                level: 'enterprise',
                label: 'sidebar.accountSettingsAdminAccounts',
            },
            {
                key: 'roles',
                module: 'merchants',
                level: 'super',
                label: 'sidebar.accountSettingsRoles',
            },
            {
                key: 'users',
                module: 'merchant_users',
                level: 'super',
                label: 'sidebar.accountSettingsAdminAccounts',
            },
        ],
    },
    {
        key: 'myAccount',
        label: 'sidebar.myAccount',
        leftIcon: 'account_report.png',
        children: [
            {
                key: 'accountingAndAudit',
                module: 'merchant_accounts',
                level: 'carpark',
                label: 'sidebar.myAccountAccountingAndAudit',
            },
            {
                key: 'subscriptionsAndPayments',
                module: 'merchant_accounts',
                level: 'enterprise',
                label: 'sidebar.myAccountSubscriptionsAndPayments',
            },],
    },
    {
        key: 'contactInfo',
        label: 'sidebar.accountSettingsContactInfo',
        leftIcon: 'My-account.png',
    },
];
export default options;
