import React, { Component } from "react";
import { connect } from "react-redux";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import userpic from "../../image/user1.png";
import authAction from "../../redux/auth/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import getMerchantUserByMidAPI from "../../api/merchant/getMerchantUserByMidAPI";
import Cookies from "universal-cookie";
import getMerchantUserContactInfoAPI from "../../api/merchant/getMerchantUserContactInfoAPI";

const { logout } = authAction;
const cookies = new Cookies();

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      contactInfo: {
        username: "",
        logo: ""
      },
      createBody: {
        web_name: "",
        web_url: "",
        company_name: "",
        desc: "",
        logo: "",
        docs: [""],
        google_id: "",
        favicon: "",
        social: {
          google: "",
          facebook: "",
          wechat: ""
        },
        contact: {
          first_name: "",
          last_name: "",
          job_title: "",
          tele: "",
          email: "",
          fax: ""
        }
      }
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  componentDidMount() {

    getMerchantUserByMidAPI(cookies.get('mid'), cookies.get('token'),
      (response) => {
        // console.log(response);
        this.setState({
          createBody: response
        })
      },
      (error) => {
        console.log(error);
        alert(error.msg || 'Unknown error. Please try again.');
      }
    );

    getMerchantUserContactInfoAPI(cookies.get('token'),
      (response) => {
        // console.log(response);
        this.setState({
          contactInfo: response
        })
      },
      (error) => {
        console.log(error);
        this.setState({ loading: false, visible: true });
        alert(error.msg || 'Unknown error. Please try again.');
      }
    );
  }

  handleLogout = () => {
    cookies.remove("token");
    cookies.remove("aggregator_token");
    cookies.remove("aggregator_id");
    cookies.remove("mid");
    this.props.logout();
    window.location.reload();
  }

  render() {
    const content = (
      <div>
        <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </div>
      // <TopbarDropdownWrapper className="isoUserDropdown">
      //   <a className="isoDropdownLink" href="# ">
      //     <IntlMessages id="themeSwitcher.settings" />
      //   </a>
      //   <a className="isoDropdownLink" href="# ">
      //     <IntlMessages id="sidebar.feedback" />
      //   </a>
      //   <a className="isoDropdownLink" href="# ">
      //     <IntlMessages id="topbar.help" />
      //   </a>
      //   <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
      //     <IntlMessages id="topbar.logout" />
      //   </a>
      // </TopbarDropdownWrapper>
    );
    // console.log(this.state.createBody.contact.first_name)
    return (
      // <Popover
      //   content={content}
      //   trigger="click"
      //   visible={this.state.visible}
      //   onVisibleChange={this.handleVisibleChange}
      //   arrowPointAtCenter={true}
      //   placement="bottomLeft"
      // >
      //   <div className="isoImgWrapper">
      //     <img alt="user" src={userpic} />
      //     <span className="userActivity online" />
      //   </div>
      // </Popover>
      <div
        style={{
          display: "flex",
          wrap: "flex-wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px"
        }}
      >
        {this.state.contactInfo.logo ? (
          <img
            src={this.state.contactInfo.logo}
            style={{ height: "50px", borderRadius: "100px", margin: "10px" }}
          />
        ) : (
            <div
              style={{
                background: "#eee",
                height: "50px",
                width: "50px",
                borderRadius: "100px",
                margin: "10px"
              }}
            />
          )}
        {/*<a className="top-bar-text" >{this.state.createBody.contact.first_name} {this.state.createBody.contact.last_name}</a>*/}
        <a className="top-bar-text">{this.state.contactInfo.username}</a>
        <a className="top-bar-text" onClick={this.handleLogout} href="# ">
          Logout
        </a>
      </div>
    );
  }
}
export default connect(
  null,
  { logout }
)(TopbarUser);
