import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'blankPage',
    component: asyncComponent(() => import('../blankPage')),
  },
  {
    path: 'authCheck',
    component: asyncComponent(() => import('../AuthCheck')),
  },
  {
    path: 'dashboard',
    component: asyncComponent(() => import('../Dashboard/index')),
  },
  // {
  //   path: 'template',
  //   component: asyncComponent(() => import('../MySiteTemplate')),
  // },
  {
    path: 'contentUpdate',
    component: asyncComponent(() => import('../MySiteContentUpdate')),
  },
  {
    path: 'SEO',
    component: asyncComponent(() => import('../MySiteSEO')),
  },
  {
    path: 'carparkInfo',
    component: asyncComponent(() => import('../CarparkSettingsCarparkInfo')),
  },
  {
    path: 'paymentOrderCSVUpload',
    component: asyncComponent(() => import('../PaymentPaymentOrdersCSVUpload')),
  },
  {
    path: 'paymentOrderImportDetails/:bid',
    component: asyncComponent(() => import('../PaymentPaymentOrdersImportDetails')),
  },
  {
    path: 'CSVUpload',
    component: asyncComponent(() => import('../CarparkSettingsCSVUpload')),
  },
  {
    path: 'carparkImportDetails/:bid',
    component: asyncComponent(() => import('../CarparkSettingsImportDetails')),
  },
  {
    path: 'carparkParticulars',
    component: asyncComponent(() => import('../CarparkSettingsCarparkParticulars')),
  },
  {
    path: 'customersCSVUpload',
    component: asyncComponent(() => import('../CustomerCSVUpload')),
  },
  {
    path: 'customersImportDetails/:bid',
    component: asyncComponent(() => import('../CustomerImportDetails')),
  },
  {
    path: 'customers',
    component: asyncComponent(() => import('../Customers')),
  },
  {
    path: 'customers/:cid/ocr',
    component: asyncComponent(() => import('../CustomerOCRRecords')),
  },
  {
    path: 'modules',
    component: asyncComponent(() => import('../Modules/index')),
  },
  {
    path: 'message',
    component: asyncComponent(() => import('../Message')),
  },
  {
    path: 'enquiry',
    component: asyncComponent(() => import('../MessageEnquiry')),
  },
  {
    path: 'messageCustomizedMessage',
    component: asyncComponent(() => import('../MessageCustomizedMessage')),
  },
  {
    path: 'oneTimePayment',
    component: asyncComponent(() => import('../PaymentsOneTimePayment')),
  },
  {
    path: 'paymentOrders',
    component: asyncComponent(() => import('../PaymentsPaymentOrders')),
  },
  {
    path: 'paymentOrders/:order_id',
    component: asyncComponent(() => import('../PaymentsPaymentOrders')),
  },
  {
    path: 'subOrders',
    component: asyncComponent(() => import('../PaymentsSubOrders')),
  },
  {
    path: 'subOrders/:invoice_id',
    component: asyncComponent(() => import('../PaymentsSubOrders')),
  },
  {
    path: 'transactions',
    component: asyncComponent(() => import('../PaymentsTransactions')),
  },
  {
    path: 'transactions/:transaction_id',
    component: asyncComponent(() => import('../PaymentsTransactions')),
  },
  {
    path: '711verification',
    component: asyncComponent(() => import('../PaymentTransactions711Verification'))
  },
  {
    path: 'cqmVerification',
    component: asyncComponent(() => import('../PaymentTransactionsCQMVerification'))
  },
  {
    path: 'settings',
    component: asyncComponent(() => import('../Settings')),
  },
  {
    path: 'contactInfo',
    component: asyncComponent(() => import('../AccountSettingsContactInfo')),
  },
  {
    path: 'basicInfo',
    component: asyncComponent(() => import('../AccountSettingsBasicInfo')),
  },
  {
    path: 'roles',
    component: asyncComponent(() => import('../AccountSettingsRoles')),
  },
  {
    path: 'users',
    component: asyncComponent(() => import('../AccountSettingsAdminAccounts')),
  },
  {
    path: 'accountingAndAudit',
    component: asyncComponent(() => import('../MyAccountAccountingAndAudit')),
  },
  {
    path: 'subscriptionsAndPayments',
    component: asyncComponent(() => import('../MyAccountSubscriptionsAndPayments')),
  },
  {
    path: 'quotaStatus',
    component: asyncComponent(() => import('../QuotaStatus')),
  },
  {
    path: 'appBanner',
    component: asyncComponent(() => import('../AppBanner')),
  },
  {
    path: 'aggregator',
    component: asyncComponent(() => import('../Aggregator')),
  },
  {
    path: 'merchants',
    component: asyncComponent(() => import('../EnterpriseMerchants')),
  },
  {
    path: 'merchantSubscription',
    component: asyncComponent(() => import('../EnterpriseMerchants/merchantsSubscriptionsPortal'))
  },
  {
    path: 'merchantRentalIncome',
    component: asyncComponent(() => import('../EnterpriseMerchants/merchantsRentalIncome'))
  },
  {
    path: 'merchantRentalIncomeImportDetails/:bid',
    component: asyncComponent(() => import('../EnterpriseMerchants/merchantsRentalIncomeImportDetails'))
  },
  {
    path: 'publicHolidays',
    component: asyncComponent(() => import('../PublicHolidays')),
  },
  {
    path: 'parkingBays/:space_id',
    component: asyncComponent(() => import('../CarparkSettingsParkingBayBatches')),
  },
  {
    path: 'parkingBaysCSVUpload/:space_id',
    component: asyncComponent(() => import('../CarparkSettingsParkingBayBatchesCSVUpload')),
  },
  {
    path: 'parkingBaysImportDetails/:bid',
    component: asyncComponent(() => import('../CarparkSettingsParkingBayBatchesImportDetails')),
  },
  {
    path: 'errorNotifications',
    component: asyncComponent(() => import('../ErrorNotifications')),
  },
  {
    path: 'records',
    component: asyncComponent(() => import('../OcrRecords')),
  },
  {
    path: 'acsBookings',
    component: asyncComponent(() => import('../ACSBookings')),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            // console.log("can reach AppRouter"),
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
